<template>
  <div
    id="thread-container"
    class="move-left"
  >
    <transition name="slide">
      <router-view></router-view>
    </transition>

    <phi-page
      color="#f3f3f3"
      class="page-highlights"
    >
      <div slot="toolbar">
        <h1 v-text="$store.getters.getSetting('title', 'Colegio Aureliano Buendía')"></h1>
      </div>

      <div class="dashboard-list phi-card">
        <data-person-dashboard-list
          :person-id="$store.state.user.id"
          @click-dashboard="$router.push({ name: 'data-dash', params: { dashboardId: $event.id }})"
        ></data-person-dashboard-list>
      </div>

      <div class="feed">
        <div
          v-for="post in feed.items"
          :key="post.thread2"
          class="post"
        >
          <div class="phi-card">
            <router-link :to="{name: 'highlight-thread', params:{threadId: post.thread2}}">
              <div class="phi-card-header">
                <h1>{{ post.title }}</h1>
                <h2>{{ post.type.singular + ' &bull; ' + $t('published') + ' ' + $date(post.publishDate) }}</h2>
              </div>
              <div class="phi-card-body">
                <div v-html="post.description"></div>
              </div>
            </router-link>

            <div
              v-if="post.blocks"
              class="phi-card-body"
            >
              <phi-block
                v-for="block in post.blocks"
                :key="block.id"
                :block="block"
              ></phi-block>
            </div>
          </div>
        </div>

        <mu-infinite-scroll
          v-if="$el && !feed.isEmpty"
          :scroller="scrollObj"
          :loading="feed.hasNext && feed.isLoading"
          @load="feed.hasNext && feed.next()"
          :loading-text="$t('notice.loading')"
        />
      </div>
    </phi-page>
  </div>
</template>


<script>


import store from '@/store/index.js';
import DataPersonDashboardList from '@/modules/data/components/Person/DashboardList.vue';

export default {
  name: 'highlights',

  components: { DataPersonDashboardList },

  data() {
    return {
      feed: [],
      limit: 5,
      busy: false
    };
  },

  methods: {
    loadMore() {
      this.busy = true;
      this.feed = store.state.api.collection(
        `/people/${store.state.user.id}/posts/inbox`,
        {limit: this.limit}
      )
    }
  },

  created() {
    this.loadMore();
  },

  computed: {
    scrollObj() {
      return this.$el.querySelector('.phi-page-body');
    }
  },

  mounted() {
    this.feed.fetch({ tags: 'highlight' });
  }
};
</script>

<style lang="scss">
#thread-container {
  height: 100%;

  .dashboard-list {
    margin-bottom: 12px;
  }

  .phi-page {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }

  #thread {
    z-index: 1;
  }
}

iframe {
  width: 100% !important;
}

.page-highlights {
  .phi-card-header {
    h1 {
      word-wrap: break-word;
    }
  }

  .phi-card-body > div {
    word-wrap: break-word;
    overflow: hidden;
  }

  .phi-page-toolbar {
    text-align: center;
    
  }

  .logo {
    max-width: 30px;
    margin-left: 16px;
  }

  .feed {
    .post {
      margin-bottom: 16px;

      img {
        max-width: 100%;
        height: auto;
      }
    }

    .gallery {
      list-style: none;
      margin: 0;
      padding: 0;

      display: flex;
      flex-wrap: wrap;

      li {
        overflow: hidden;
        margin: 0.1em;
        width: 100px;
        height: 100px;

        &:first-child {
          width: auto;
          height: auto;
        }
      }
    }
  }
}
</style>